import { Enum } from '@utocat/catalizr-const-sharing-lib';
// Const
import * as commonConst from '../actions/const/common.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investmentConst from '../actions/const/investment.const';
import * as documentsConst from '../actions/const/documents.const';
import * as loginConst from '../../pages/login/actions/login.const';
import * as AccountConst from '../../pages/account/actions/account.const';
import * as dashboardConst from '../../pages/dashboard/common/const/dashboard.const';

const initState = {
  isLoading: false,
  goNext: false,
  potentialPlaces: [],
  informationMessage: '',
  errorMessage: '',
  errorOnGetComments: false,
  errorOnPostComments: false,
  version: process.env.REACT_APP_FRONTEND_VERSION,
  debug: process.env.NODE_ENV === 'development',
  currencies: [],
  banners: [],
  instrumentTypes: [],
  template: {},
  errorTemplate: {},
  isDraftMode: false,
  editingDraftMode: false,
};

const common = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_LOGOUT_FULFILLED:
    case commonConst.CLEAR_REDUX:
    case landingConst.INIT_DEFAULT:
      return {
        ...initState,
        isDraftMode: state.isDraftMode,
        editingDraftMode: state.editingDraftMode,
      };

    case landingConst.INIT_VIEW_PENDING:
      return {
        ...state,
        isLoading: true,
        goNext: false,
      };

    case investDetailsConst.INIT_FORM_PENDING:
    case landingConst.START_NEW_OPERATION_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
    case landingConst.GET_TEMPLATE_DOCUMENTS_PENDING:
    case investDetailsConst.SUBMIT_FORM_PENDING:
    case investDetailsConst.SUBMIT_TEMPLATE_FORM_PENDING:
    case investDetailsConst.START_NEW_ERROR_TEMPLATE_PENDING:
    case loginConst.SUBMIT_FORM_PENDING:
    case investDetailsConst.CONTACT_SUPPORT_PENDING:
    case documentsConst.INIT_DOCUMENTS_PENDING:
    case investmentConst.SET_STATUS_PENDING:
    case AccountConst.INIT_FORM_PENDING:
    case AccountConst.SUBMIT_FORM_PENDING:
    case dashboardConst.GET_PLATINE_PENDING:
      return { ...state, isLoading: true };

    case commonConst.SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case landingConst.START_NEW_OPERATION_TRANSMIT_FULFILLED:
    case investDetailsConst.SUBMIT_FORM_FULFILLED:
    case AccountConst.INIT_FORM_FULFILLED:
    case AccountConst.SUBMIT_FORM_FULFILLED:
      return { ...state, isLoading: false, goNext: true };

    case investDetailsConst.SUBMIT_TEMPLATE_FORM_FULFILLED:
      return { ...state, isLoading: false };

    case landingConst.START_NEW_OPERATION_FULFILLED:
    case landingConst.START_NEW_OPERATION_REJECTED:
    case landingConst.START_NEW_TEMPLATE_REJECTED:
    case landingConst.GET_TEMPLATE_DOCUMENTS_REJECTED:
    case investDetailsConst.SUBMIT_FORM_REJECTED:
    case investDetailsConst.SUBMIT_TEMPLATE_FORM_REJECTED:
    case loginConst.SUBMIT_FORM_FULFILLED:
    case loginConst.SUBMIT_FORM_REJECTED:
    case investDetailsConst.CONTACT_SUPPORT_FULFILLED:
    case investDetailsConst.CONTACT_SUPPORT_REJECTED:
    case documentsConst.INIT_DOCUMENTS_FULFILLED:
    case documentsConst.INIT_DOCUMENTS_REJECTED:
    case AccountConst.INIT_FORM_REJECTED:
    case AccountConst.SUBMIT_FORM_REJECTED:
    case investmentConst.SET_STATUS_FULLFILED:
    case investmentConst.SET_STATUS_REJECTED:
    case landingConst.INIT_INVESTMENT:
    case dashboardConst.GET_PLATINE_REJECTED:
    case dashboardConst.GET_PLATINE_FULFILLED:
      return { ...state, isLoading: false };

    case landingConst.START_NEW_TEMPLATE_FULFILLED:
      return { ...state, isLoading: false, template: action.template };

    case landingConst.GET_TEMPLATE_DOCUMENTS_FULFILLED: {
      const documents = action.documents.filter(
        document =>
          document.origin !== 'generated' &&
          document.type_document !== Enum.Documents.Type.CUSTOM_TEMPLATE_DOCUMENT,
      );
      return { ...state, isLoading: false, template: { ...state.template, documents } };
    }

    case investDetailsConst.START_NEW_ERROR_TEMPLATE_FULFILLED:
      return {
        ...state,
        errorTemplate: action.errorTemplate,
        isLoading: false,
        errorMessage: '',
        informationMessage: 'Le signalement des erreurs a bien été pris en compte',
      };

    case investDetailsConst.START_NEW_ERROR_TEMPLATE_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage:
          "Erreur lors de l'envoi des champs signalés en erreur, veuillez réessayer plus tard",
        informationMessage: '',
      };

    case investDetailsConst.INIT_FORM_FULFILLED:
    case investDetailsConst.INIT_FORM_REJECTED:
      return { ...state, isLoading: false, goNext: false };

    case commonConst.SET_POTENTIAL_PLACES:
      return { ...state, potentialPlaces: action.potentialPlaces };

    case commonConst.GET_POTENTIAL_PLACES_FULFILLED:
      return { ...state, potentialPlaces: action.payload.data };

    case commonConst.SET_INFORMATION_MESSAGE:
      return { ...state, informationMessage: action.message, errorMessage: '' };

    case commonConst.SET_ERROR_MESSAGE:
      return { ...state, informationMessage: '', errorMessage: action.message };

    case commonConst.CLEAR_MESSAGES:
      return {
        ...state,
        informationMessage: '',
        errorMessage: '',
        errorOnPostComments: false,
        errorOnGetComments: false,
      };

    case dashboardConst.GET_OPERATION_COMMENTS_REJECTED: {
      if (action.err.response.status !== 404) {
        return { ...state, errorOnGetComments: true };
      }
      return {
        ...state,
      };
    }

    case dashboardConst.GET_OPERATION_COMMENTS_FULFILLED:
      return {
        ...state,
        errorOnGetComments: false,
      };

    case dashboardConst.POST_OPERATION_COMMENT_REJECTED: {
      return {
        ...state,
        errorOnPostComments: true,
      };
    }
    case dashboardConst.POST_OPERATION_COMMENT_FULFILLED:
      return {
        ...state,
        errorOnPostComments: false,
      };
    case landingConst.EDIT_TRANSMIT_OPERATION_REJECTED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case landingConst.EDIT_TRANSMIT_OPERATION_FULFILLED:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
      };
    case landingConst.EDIT_TRANSMIT_OPERATION_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'La mise à jour a échoué. Veuillez réessayer ultérieurement',
      };
    case dashboardConst.SET_SUBMIT_COMPANY_SHARES_VALUATION_FULFILLED:
      return {
        ...state,
        isLoading: false,
        informationMessage:
          'La mise à jour a bien été effectuée et communiquée aux banques concernées.',
      };
    case commonConst.SET_GO_NEXT:
      return {
        ...state,
        goNext: action.goNext,
      };
    case investmentConst.POST_CANCELLED_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case investmentConst.POST_CANCELLED_FULFILLED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case investmentConst.POST_CANCELLED_REJECTED: {
      if (action.payload && action.payload.response && action.payload.response.status === 404) {
        let errorMsg = action.payload.response.data.message;
        if (action.payload.response.data && action.payload.response.data.error === 'Not Found') {
          errorMsg = 'Erreur : Dossier introuvable (déconnectez vous et réessayez)';
        }
        return {
          ...state,
          isLoading: false,
          errorMessage: errorMsg,
        };
      }
      return {
        ...state,
        isLoading: false,
        errorMessage:
          "Une erreur est survenue lors de l'annulation de l'opération. Veuillez réessayer ultérieurement.",
      };
    }
    case dashboardConst.RESET_CANCELLED_ERROR_MSG: {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
      };
    }

    case dashboardConst.CLOSE_OPERATION_ERROR_MESSAGE: {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
      };
    }

    case commonConst.GET_CURRENCIES: {
      return {
        ...state,
        currencies: action.currencies,
      };
    }

    case commonConst.GET_BANNERS: {
      return {
        ...state,
        banners: action.banners,
      };
    }
    case commonConst.GET_INSTRUMENT_TYPES_FULFILLED: {
      return {
        ...state,
        instrumentTypes: action.payload.data,
      };
    }
    case dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_PENDING:
    case dashboardConst.SEND_POSITIONS_UPDATED_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        informationMessage: '',
      };
    case dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_FULFILLED:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        informationMessage: 'Votre insertion manuelle a bien été prise en compte',
      };
    case dashboardConst.SEND_SHAREHOLDING_MANUAL_INSERTION_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: "Erreur lors de l'insertion, veuillez réessayer plus tard",
        informationMessage: '',
      };
    case dashboardConst.SEND_POSITIONS_UPDATED_FULFILLED:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        informationMessage:
          'La mise à jour des positions a bien été effecftuée communiquée aux établissements bancaires concernés.',
      };
    case dashboardConst.SEND_POSITIONS_UPDATED_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Erreur lors de la mise à jour, veuillez réessayer plus tard',
        informationMessage: '',
      };
    case dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        informationMessage: '',
      };
    case dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_FULFILLED:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        informationMessage: 'La suppression a bien été prise en compte',
      };
    case dashboardConst.DELETE_SHAREHOLDING_MANUALLY_INSERTED_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Erreur lors de la suppression, veuillez réessayer plus tard',
        informationMessage: '',
      };

    case commonConst.SET_DRAFT_MODE: {
      return { ...state, isDraftMode: true };
    }

    case commonConst.SET_DRAFT_MODE_EDITION: {
      return { ...state, isDraftMode: true, editingDraftMode: action.payload };
    }

    case commonConst.CLEAR_DRAFT_MODE: {
      return { ...state, isDraftMode: false, editingDraftMode: false };
    }

    case commonConst.REFRESH_TEMPLATE_DOCUMENT_LIST: {
      return {
        ...state,
        isLoading: false,
        template: { ...state.template, template_documents: action.documentList },
      };
    }
    case commonConst.REFRESH_CUSTOM_DOCUMENT_LIST: {
      return {
        ...state,
        isLoading: false,
        template: { ...state.template, custom_documents: action.documentList },
      };
    }
    case commonConst.REFRESH_CUSTOM_FIELD_LIST: {
      return {
        ...state,
        isLoading: false,
        template: { ...state.template, custom_fields: action.customFieldList },
      };
    }

    case commonConst.EDITED_TEMPLATE_DOCUMENT: {
      return {
        ...state,
        isLoading: false,
        template: { ...state.template, edited_template_document: action.document },
      };
    }

    default:
      return state;
  }
};

export default common;
